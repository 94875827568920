import React from "react";
import {
  route,
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Dashboard from "./Dashboard";

import Category from "./Pages/Module/Category/Category";
import SubCategory from "./Pages/Module/Subcategory/subCategory";
import HomeCategory from "./Pages/Module/HomeCategory/Homecategory";
import AddProduct from "./Pages/Module/product/Addproduct";
import ProductListing from "./Pages/Module/product/ProductListing";
// import Signup from "./Pages/Module/Signup";

import Slider from "./Pages/Module/Slider/Slider";
import Banner from "./Pages/Module/Banner";
import WebsiteInfo from "./Pages/Module/WebsiteInfo";
import AllOrder from "./Pages/Module/ManageOrders/Order";
// import NewOrder from "./Pages/Module/NewOrder";
// import Process from "./Pages/Module/Process";
// import Shipped from "./Pages/Module/Shipped";
// import Delivered from "./Pages/Module/Delivered";
// import Failed from "./Pages/Module/Failed";
// import List from "./Pages/Module/List";
// import Signup from "./Pages/Module/SignIn";

import 'react-toastify/dist/ReactToastify.css';
import Dashboardd from "./Dashboard";
import SignIn from "./Pages/Module/SignIn";
import Login from "./Auth/Login";
// import AddStaticPage from "./Pages/Module/StaticPage/AddStaticPage";
import ShippingCharges from "./Pages/Module/shippingCharges";
import AllUserList from "./Pages/Module/Alluserlist";
import StaticPageList from "./Pages/Module/StaticPage/staticPageList";
import Changepswd from "./Pages/Module/Changepassword";
// import Childcategory from "./Pages/Module/Childcategory";
// import Childcategory from "./Pages/Module/ChildCategory/Childcategory";
import Howtouse from "./Pages/Module/Stepstouse/Howtouse";
import EditCategory from "./Pages/Module/Category/Editcategory";
import EditSubCategory from "./Pages/Module/Subcategory/Editsubcategory";
import EditHomeCategory from "./Pages/Module/HomeCategory/EditHomecategory";
// import EditChildcategory from "./Pages/Module/ChildCategory/EditChildcategory";
import UpdateProduct from "./Pages/Module/product/Updateproduct";
import UpdateSlider from "./Pages/Module/Slider/updateSlider";
import UpdateStaticPage from "./Pages/Module/StaticPage/updateStaticpage";
import EditHowtouse from "./Pages/Module/Stepstouse/EditHowtouse";
import ReturnOrders from "./Pages/Module/ManageOrders/ReturnOrders";
import ReplaceOrders from "./Pages/Module/ManageOrders/ReplaceOrders";
import Failed from "./Pages/Module/ManageOrders/Failed";
import NewOrder from "./Pages/Module/ManageOrders/NewOrder";
import Process from "./Pages/Module/ManageOrders/Process";
import Shipped from "./Pages/Module/ManageOrders/Shipped";
import Delivered from "./Pages/Module/ManageOrders/Delivered";
import Viewuser from "./Pages/Module/Userview";
import ProductDetails from "./Pages/Module/product/ProductDetails";
import SubscriberList from "./Pages/Module/SubscriberList";
import Childcategory from "./Pages/Module/childCategory/Childcategory";
import EditChildcategory from "./Pages/Module/childCategory/Editchildcategory";
import OrderDetails from "./OrderDetails";
import PrintInvoice from "./Pages/Module/PrintInvoice";
import Blog from "./Pages/Module/Blogs/Blog";
import Editblog from "./Pages/Module/Blogs/Editblog";
import ContactList from "./Pages/Module/ContactList";
import AdmissionList from "./Pages/Module/AdmissionList";


import Testimonial from "./Pages/Module/Testimonial";
import Gallery from "./Pages/Module/Gallery/Gallery";
import UpdateGallery from "./Pages/Module/Gallery/UpdateGallery";


function App() {

  const router = createBrowserRouter([
    {
      path: '/SignIn',
      element: <SignIn />
    }, {
      path: "/",
      element: <Dashboard />
    },
    {
      path: "/view-user",
      element: <Viewuser />
    },
    {
      path: '/Category',
      element: <Category />
    }, {
      path: '/SubCategory',
      element: <SubCategory />
    }, {
      path: '/HomeCategory',
      element: <HomeCategory />
    }, {
      path: '/AddProduct',
      element: <AddProduct />
    }, {
      path: '/updateProduct',
      element: <UpdateProduct />
    }, {
      path: '/ProductListing',
      element: <ProductListing />
    }, {
      path: '/WebsiteInfo',
      element: <WebsiteInfo />
    }, {
      path: '/Slider',
      element: <Slider />
    }, {
      path: '/Banner',
      element: <Banner />
    }, {
      path: '/update_slider',
      element: <UpdateSlider />
    }, {
      path: "/shippingcharges",
      element: <ShippingCharges />
    }, {
      path: "/alluserlist",
      element: <AllUserList />
    }, {
      path: "/staticpageList",
      element: <StaticPageList />
    }, {
      path: "/update_staticpage",
      element: <UpdateStaticPage />
    }, {
      path: "/change-password",
      element: <Changepswd />
    },
    {
      path: "/childcategory",
      element: <Childcategory />
    },
    {
      path: "/edit-childcategory",
      element: <EditChildcategory />
    },
    {
      path: "/How-to-use",
      element: <Howtouse />
    }, {
      path: "/edithowtouse",
      element: <EditHowtouse />
    }, {
      path: "/edit_category",
      element: <EditCategory />
    }, {
      path: "/edit-subcategory",
      element: <EditSubCategory />
    }, {
      path: "/edit_homecategory",
      element: <EditHomeCategory />
    },
    {
      path: '/AllOrder',
      element: <AllOrder />
    }, {
      path: '/new-order',
      element: <NewOrder />
    }, {
      path: '/process',
      element: <Process />
    }, {
      path: '/shipped',
      element: <Shipped />
    }, {
      path: '/delivered',
      element: <Delivered />
    }, {
      path: '/failed',
      element: <Failed />
    }, {
      path: '/return-orders',
      element: <ReturnOrders />
    }, {
      path: "/order-details",
      element: <OrderDetails />
    }, {
      path: "/replace-orders",
      element: <ReplaceOrders />
    },
    {
      path: "/product-details",
      element: <ProductDetails />
    }, {
      path: "/subscriber-list",
      element: <SubscriberList />
    }, {
      path: "/print-invoice",
      element: <PrintInvoice />
    }, {
      path: "/blogs",
      element: <Blog />
    }, {
      path: "/update-blogs",
      element: <Editblog />
    }, {
      path: "/contact-list",
      element: <ContactList />
    },
    {
      path: "/admission-list",
      element: <AdmissionList />
    }, {
      path: "/testimonial-list",
      element: <Testimonial />
    }, {
      path: '/Gallery',
      element: <Gallery />
    }, {
      path: '/update_gallery',
      element: <UpdateGallery />
    }


  ])
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
