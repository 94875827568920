import Container from 'react-bootstrap/Container';
// import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
// import Count from './Count';

// import OrderCard from './OrderDetails';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Badge from 'react-bootstrap/Badge';
// import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
import './App.css'
import './responsiveTable.css'

import NavBar from './NavBar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
const Dashboardd = () => {
  const [orders, setOrders] = useState([]);
  const [counts, setCounts] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [webInfo, setWebInfo] = useState([]);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  const getwebsiteinfo = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${BASE_URL}admin/getWebsiteInfo`, {
        headers: {
          "x-access-token": `${token}`,
          "version": "1.0.0",
          "Content-Type": "Application/json"
        }
      }).then((response) => {
        if (response.data.success == false) {
          if (response.data.error_code == 461) {
            navigate("/SignIn");
          }
          // toast.error(response.data.message);
        } else {
          // toast.success(response.data.message);
          // setWebinfo(response.data.data);
          // setValue(response.data.data);
          // setId()

          setWebInfo(response.data.data);
          console.log("websiteinfo", response.data.data);
          if (response.data.data._id) {
            setId(response.data.data._id);
          }

        }
      }).catch((error) => {
        console.log(error);
      })

  }

  // const getAllOrders=()=>{
  //   const token=localStorage.getItem("token");
  //   axios
  //   .post(`${BASE_URL}admin/getAllOrder`,{
  //       headers:{
  //           "x-access-token":`${token}`,
  //           "Content-Type":"Application/json",
  //           "version":"1.0.0"
  //       }
  //     }).then((response)=>{
  //       if(response.data.success==false)
  //       {
  //           if(response.data.error_code==461)
  //           {
  //               navigate("/SignIn");
  //           }

  //       }else{
  //         setOrders(response.data.data);
  //           console.log("setOrders===============>",response.data.data);
  //           // if(response.data.data._id)
  //           // {
  //           //     setId(response.data.data._id); 
  //           // }

  //       }
  //   }).catch((error)=>{
  //       console.log(error);
  //   })

  // }




  const DashboardCount = () => {
    let token = localStorage.getItem('token');
    axios
      .post(`${BASE_URL}auth/dashboardCount`, {}, {
        headers: {
          "x-access-token": `${token}`,
          "Content-type": "Application/json",
          "version": "1.0.0"
        }
      }).then((response) => {
        setCounts(response.data.data);
        console.log("counts", response.data.data);
      }).catch((error) => {
        console.log(error);
      })
  }


  useEffect(() => {
    getwebsiteinfo();
    DashboardCount();
  }, [])
  return (
    <>

      <NavBar />
      <Container fluid className='content'>
        <Container className='mb-3 w-100'>
          <div className='Count-sec'>
            <p className='pt-3' style={{ color: 'grey', fontSize: '25px' }}>Dashbaord</p>
            <div className='mt-3 p-2' >
              <Row className=' w-100'>
                {/* <Col className='' lg={3} md={4} xs={6} sm={4}>
<div className='taskdiv'>
  <Row className=''>
    <Col className='p-1 p-sm-1 p-xs-1 p-md-4 text-center col-md-3' md={3}>
      <span  className='p-1 p-sm-1 p-xs-1 p-md-4 mt-3  '>  <i className='		fa fa-cart-plus mt-3' style={{fontSize:'45px'}}></i></span>
  
    </Col>
    <Col  className='p-0 p-md-3 text-center' >
    <p className='mb-1 mb-sm-0 mb-xs-0 mb-md-1'>Pending Order</p>
   <h4>{counts.PendingOrderCount}</h4>
  
    </Col>
  </Row>


</div>
</Col> */}


                {/* <Col className='p-1 p-sm-1 p-xs-1 p-md-4 text-center col-md-3' lg={3} md={4} xs={6} sm={4}>
                  <div className='taskdiv'>
                    <Row className=''>
                      <Col className='p-0 p-md-4 text-center' md={3}>
                        <span className='p-1 p-sm-1 p-xs-1 p-md-4 mt-3'>  <i className='		fa fa-cart-plus mt-3' style={{ fontSize: '45px' }}></i></span>

                      </Col>
                      <Col className='p-0 p-md-3 text-center' >
                        <p className='mb-1 mb-sm-0 mb-xs-0 mb-md-1'>Pending Order</p>
                        <h4>{counts.PendingOrderCount}</h4>
                     
                      </Col>
                    </Row>


                  </div>
                </Col>
                <Col className='p-1 p-sm-1 p-xs-1 p-md-4 text-center col-md-3' lg={3} md={4} xs={6} sm={4}>
                  <div className='taskdiv1'>
                    <Row className=''>
                      <Col className='p-0 p-md-4 text-center' md={3}>
                        <span className='p-1 p-sm-1 p-xs-1 p-md-4 mt-3'>  <i className='		fa fa-user-o mt-3' style={{ fontSize: '45px' }}></i></span>

                      </Col>
                      <Col className='p-0 p-md-3 text-center' >
                        <p className='mb-1 mb-sm-0 mb-xs-0 mb-md-1'>Total User</p>
                        <h4>{counts.UserCount}</h4>
                      </Col>
                    </Row>


                  </div>
                </Col>
                <Col className='p-1 p-sm-1 p-xs-1 p-md-4 text-center col-md-3 col-lg-3 col-md-4 col-sm-4 col-6' lg={3} md={4} xs={6} sm={4}>
                  <div className='taskdiv2' style={{ backgroundColor: "green" }}>
                    <Row className=''>
                      <Col className='p-0 p-md-4 text-center' md={3}>
                        <span className='p-1 p-sm-1 p-xs-1 p-md-4 mt-3 '>  <i className='		fa fa-cart-plus mt-3' style={{ fontSize: '45px' }}></i></span>

                      </Col>
                      <Col className='p-0 p-md-3 text-center' >
                        <p className='mb-1 mb-sm-0 mb-xs-0 mb-md-1'>Success order</p>
                        <h4>{counts.SuccessOrderCount}</h4>

                      </Col>
                    </Row>


                  </div>
                </Col>

                <Col className='p-1 p-sm-1 p-xs-1 p-md-4 text-center col-md-3 col-lg-3 col-md-4 col-sm-4 col-6' lg={3} md={4} xs={6} sm={4}>
                  <div className='taskdiv2'>
                    <Row className=''>
                      <Col className='p-0 p-md-4 text-center' md={3}>
                        <span className='p-1 p-sm-1 p-xs-1 p-md-4 mt-3 '>  <i className='		fa fa-cart-plus mt-3' style={{ fontSize: '45px' }}></i></span>

                      </Col>
                      <Col className='p-0 p-md-3 text-center' >
                        <p className='mb-1 mb-sm-0 mb-xs-0 mb-md-1'>Cancelled order</p>
                        <h4>{counts.cancelledOrderCount}</h4>
                      </Col>
                    </Row>


                  </div>
                </Col> */}


              </Row>
            </div>

          </div>
        </Container>





      </Container>
    </>
  );
}
export default Dashboardd;