import axios from "axios";
import { useEffect, useState } from "react";
import { Image, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateGallery = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [name, setName] = useState(state.item.name);
    const [file, setFile] = useState("");
    const [status, setStatus] = useState(state.item.status);
    const token = localStorage.getItem("token");
    const [id, setId] = useState(state.item._id);

    const [categories, setCategories] = useState([]);

    const [category, setCategory] = useState(state.item.category_id);


    const maxFileSize = 3000000;

    useEffect(() => {
        getAllCategory();
    }, [])

    const getAllCategory = () => {
        axios
            .get(`${BASE_URL}admin/getAllCategory`, {
                headers: {
                    "Content-Type": "Application/json",
                    "x-access-token": `${token}`,
                    "version": "1.0.0"
                }
            }).then((response) => {
                if (response.data.success == false) {
                    // toast.error(response.data.message);
                } else {
                    // toast.success(response.data.messsage);
                    setCategories(response.data.data);
                    console.log("response", response.data.data);
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const uGallery = () => {
        // console.log(file[0].size);
        const formData = new FormData();
        formData.append("category_id", category);
        formData.append("name", name);
        formData.append("status", status);
        formData.append("image", file[0]);
        if (file[0] == "") {
            if (file[0].size > maxFileSize) {
                toast.error("uploaded file is too large");
                return;
            }
        }

        axios
            .post(`${BASE_URL}admin/updateSlider/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/formdata",
                    "version": "1.0.0",
                    "x-access-token": `${token}`
                }
            }).then((response) => {
                if (response.data.success == false) {
                    toast.error(response.data.message);
                }
                else {
                    toast.success(response.data.message);
                    navigate('/Gallery')
                    setId("");
                    setName("");
                    setFile("");

                }
            })
    }


    return (
        <>
            <NavBar />
            <div className='container '>
                <div className='row d-flex justify-content-center align-items-center mt-5'>
                    <div className='col-10 p-5' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div className="row">
                            <div>
                                <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                                    Home Slider

                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="formleb">Category</label>
                                {/* <input type="file" className="form-control"/> */}
                                <select className="forminp"
                                    name="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option>--select--</option>
                                    {categories.length > 0 && categories.map((item) => (

                                        <option value={item._id}>{item.name}</option>
                                    ))}


                                </select>

                            </div>


                            <div className="col-md-6 ">
                                <label className="formleb">Name</label>
                                <input type="text" className=" forminp form-control"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />

                            </div>




                            <div className="col-md-6 ">
                                <label className="formleb">Select image</label>
                                <input type="file" className="forminp form-control" accept=".png , .jpeg , .jpg "
                                    name="file"
                                    onChange={(e) => setFile(e.target.files)}
                                />
                                <img src={state.item.image} className="img-fluid" width="100px" />
                            </div>


                            <div className="col-md-6">
                                <label className="formleb">Status</label>
                                <select className="forminp"
                                    name="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option>--select--</option>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                </select>

                            </div>
                            <div className="col-md-6 d-flex justify-content-center align-items-center ">

                                <button type="button" className=" formbtn " onClick={() => uGallery()} style={{ marginTop: "32px", padding: "10px", width: "100%" }}>Save</button>

                            </div>
                        </div>

                    </div>
                </div>


            </div>



        </>

    );
}
export default UpdateGallery;