import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Image, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import NavBar from "../../../NavBar";
import { useNavigate } from "react-router-dom";

const Slider = () => {
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
  const fileInputRef = useRef(null);

    const [allslider, setAllslider] = useState([]);
    const [name, setName] = useState("");
    const [file, setFile] = useState("");
    const [status, setStatus] = useState("");
    const [title, setTitle] = useState("");
    const [heading1, setHeading1] = useState("");
    const [heading2, setHeading2] = useState("");
    const [slug, setSlug] = useState("");
    const token = localStorage.getItem("token");
    const [id, setId] = useState("");
    const maxFileSize=3000000;


    const getAllSlider = () => {
        axios
            .get(`${BASE_URL}admin/getAllSlider`, {
                headers: {
                    "Content-Type": "Application/json",
                    "x-access-token": `${token}`,
                    "version": "1.0.0"
                }
            }).then((response) => {
                if (response.data.success == false) {
                    // toast.error(response.data.message);
                } else {
                    // toast.success(response.data.messsage);
                    setAllslider(response.data.data);
                    console.log("response", response.data.data);
                }
            }).catch((error) => {
                console.log(error);
            })
    }




    const AddSlider = () => {
        // console.log(file[0].size);

        if (!file[0]) {
            toast.error("kindly select image for slider");
            return;
        }
         if(file[0].size>maxFileSize)
        {
         toast.error("uploaded image is too large");
         return;
        }
        if (!slug) {
            toast.error("Please Enter slug");
            return;
        }
        if (!title) {
            toast.error("Please Enter title");
            return;
        }
        if (!heading1) {
            toast.error("Please Enter heading1");
            return;
        }
        if (!heading2) {
            toast.error("Please Enter heading2");
            return;
        }
       
        const formData = new FormData();
        formData.append("name", name);
        formData.append("status", status);
        formData.append("slug", slug);
        formData.append("title", title);
        formData.append("heading1", heading1);
        formData.append("heading2", heading2);





        formData.append("image", file[0]);
        // console.log(file);

        // let api="addSlider";
        // if(id)
        // {
        //  api="updateSlider";
        //  formData.append("id",id);
        // }

        

        axios
            .post(`${BASE_URL}admin/addSlider`, formData, {
                headers: {
                    "Content-Type": "multipart/formdata",
                    "version": "1.0.0",
                    "x-access-token": `${token}`
                }
            }).then((response) => {
                if (response.data.success == false) {
                    toast.error(response.data.message);
                }
                else {
                    toast.success(response.data.message);
                    getAllSlider();
                    setId("");
                    setName("");
                    // setFile("");
                    setSlug("");
                    setHeading1("");
                    setHeading2("");
                    setTitle("");
                    setStatus("");
                    fileInputRef.current.value = "";


                }
            })
    }

    useEffect(() => {
        getAllSlider();
        // console.log(file);

    }, [])


    // const updateSlider=()=>{


    //     axios
    //     .post(`${BASE_URL}admin/updateSlider/${id}`)
    // }

    const delSlider = (id) => {
        if (window.confirm("Do you really want to delete this item?")) {

        } else {
    
          return;
        }
        axios
            .delete(`${BASE_URL}admin/deleteSlider/${id}`,
                {
                    headers: {
                        "Content-Type": "Application/json",
                        "version": "1.0.0",
                        "x-access-token": `${token}`
                    }
                }).then((response) => {
                    if (response.data.success == false) {
                        toast.error(response.data.message);
                    }
                    else {
                        toast.success(response.data.message);
                        getAllSlider();
                    }
                }).catch((error) => {
                    console.log(error);
                })
    }
    return (
        <>
            <NavBar />
            <div className='container '>
                <div className='row d-flex justify-content-center align-items-center mt-5'>
                    <div className='col-10 p-3 p-md-5' style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div className="row">
                            <div>
                                <div className="dashboard mb-2 heading" style={{ fontSize: '25px' }}>
                                    Home Slider

                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <label className="formleb">Name</label>
                                <input type="text" className=" forminp form-control"
                                    name="name"
                                    placeholder="Enter name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />

                            </div>
                            <div className="col-md-6 ">
                                <label className="formleb"> Image</label>
                                <input type="file" className="forminp form-control" accept=".png , .jpeg , .jpg "
                                    name="file"
                                     ref={fileInputRef}
                                    onChange={(e) => setFile(e.target.files)}
                                />
                            </div>
                            <div className="col-md-6 ">
                                <label className="formleb"> Title</label>
                                <input type="text" className="forminp form-control"
                                    name="title"
                                    placeholder="Enter title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />

                            </div>
                            <div className="col-md-6 ">
                                <label className="formleb">Heading 1</label>
                                <input type="text" className="forminp form-control"
                                    name="heading1"
                                    value={heading1}
                                    placeholder="Enter heading 1"

                                    onChange={(e) => setHeading1(e.target.value)}
                                />

                            </div>
                            <div className="col-md-6 ">
                                <label className="formleb">Heading 2</label>
                                <input type="text" className="forminp form-control"
                                    name="heading2"
                                    value={heading2}
                                    placeholder="Enter headind 2"
                                    onChange={(e) => setHeading2(e.target.value)}
                                />

                            </div>

                            <div className="col-md-6  ">
                                <label className="formleb">Slug</label>
                                <input type="text" className="forminp form-control"
                                    name="slug"
                                    value={slug}
                                    placeholder="Enter slug"
                                    onChange={(e) => setSlug(e.target.value)}
                                />

                            </div>

                            <div className="col-md-6">
                                <label className="formleb">Status</label>
                                {/* <input type="file" className="form-control"/> */}
                                <select className="forminp"
                                    name="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option>--select--</option>
                                    <option>Active</option>
                                    <option>Inactive</option>

                                </select>

                            </div>
                            <div className="col-md-6 d-flex justify-content-center align-items-center ">

                                <button type="button" className=" formbtn " onClick={() => AddSlider()} style={{ marginTop: "32px", padding: "10px", width: "100%" }}>Save</button>

                            </div>
                        </div>

                    </div>
                </div>


            </div>

            <div className="scrollit container ">
                <span style={{ fontSize: '20px', fontWeight: '600' }} className="p-2">Category Listing</span>
                <hr style={{ width: '165px', fontWeight: '700', color: 'grey' }}></hr>
                <div style={{ overflowX: "scroll", scrollBehavior: "smooth" }}>
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                {/* <th>#</th> */}
                                <th>Image</th>
                                <th> Name</th>
                                <th>Title </th>

                                <th>Heading 1</th>
                                <th>Heading 2</th>
                                <th> Slug</th>
                                <th>Status</th>

                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {
                                allslider.map((item, index) => (
                                    <tr key={index}>

                                        <td><Image
                                            src=
                                            {item.image}
                                            style={{ width: '40px' }}></Image></td>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            {item.title}
                                        </td>
                                        <td>
                                            {item.heading1}
                                        </td>
                                        <td>
                                            {item.heading2}
                                        </td>
                                        <td>
                                            {item.slug}
                                        </td>
                                        <td>
                                            {item.status}
                                        </td>
                                        <td>

                                            <button type="button" className="btn btn-primary " onClick={() => navigate('/update_slider', { state: { item } })} ><i className="fa fa-edit"></i> </button>
                                            &nbsp; &nbsp;
                                            <button type="button" className="btn btn-danger " onClick={() => delSlider(item._id)}><i className='fa fa-trash'></i> </button>
                                        </td>
                                    </tr>

                                ))
                            }


                        </tbody>
                    </Table>
                </div>

            </div>

        </>

    );
}
export default Slider;